import React, { useState } from 'react';

import { ButtonMain, ButtonSecondary, IconName, Modal } from '@app/ui-kit';

import s from './ModalHowToVideoGuide.module.scss';

export interface ModalHowToVideoGuide {
  youtubeUrl: string;
  buttonLabel: string;
  buttonIcon: IconName;
  buttonClassName?: string;
}

const ModalHowToVideoGuide = ({
  youtubeUrl,
  buttonIcon,
  buttonLabel,
  buttonClassName,
}: ModalHowToVideoGuide) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ButtonSecondary
        label={buttonLabel}
        iconName={buttonIcon}
        onClick={() => setModalOpen(true)}
        className={buttonClassName}
      />
      <Modal isOpen={modalOpen} containerClassName={s.container}>
        <div className={s.video}>
          <iframe
            src={youtubeUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
        <ButtonMain label="Close" onClick={() => setModalOpen(false)} />
      </Modal>
    </>
  );
};

export default ModalHowToVideoGuide;
