/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import {
  createPublicClient,
  formatEther,
  formatUnits,
  parseEther,
  webSocket,
} from 'viem';
import { useAccount, useBalance, useContractRead } from 'wagmi';
import { useMutation, useQuery } from '@apollo/client';
import {
  SaleGetDepositWalletDocument,
  SaleGetPurchaseHistoryDocument,
  SaleRunDepositWalletPurchaseDocument,
  SaleWatchDepositWalletDocument,
} from '@app/__generated__/graphql';
import { toast } from 'react-toastify';

import { useUserContext } from '@app/context/UserContextProvider';
import { contracts } from '@app/contracts';
import {
  ToFixedRounding,
  getUserDiscounts,
  sendAnalyticsEvent,
  toFixed,
} from '@app/utils';
import { ProgressStep } from '@app/components/ProgressIndicator';
import ToastNotification, {
  NotificationType,
} from '@app/components/ToastNotification';
import investorClient from '@app/services/investor';
import { usePollInterval } from '.';
import { defaultChain } from '@app/context/Web3Modal/Web3Modal';
import { appConfig } from '@app/config';

const INPUT_PRECISION = 3;
const BUY_STEPS = ['Awaiting deposit', 'Completing purchase'];
const MIN_DEPOSIT_AMOUNT = 1;

export const useDepositBuyWOFR = () => {
  const { address } = useAccount();
  const { user, balanceWOFR } = useUserContext();

  const [amountWOFR, setAmountWOFR] = useState<string | null>(null);
  const [amountUSDT, setAmountUSDT] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState<ProgressStep>({
    stepIndex: -1,
    lineIndex: -1,
  });
  const [isDepositTaken, setIsDepositTaken] = useState(false);
  const { data: wofrPriceData } = useContractRead({
    ...contracts.tokenSale,
    functionName: 'tokenPriceUsdt',
    enabled: Boolean(user),
  });
  const { data: depositData, refetch: refetchWalletWatched } = useQuery(
    SaleGetDepositWalletDocument,
  );
  const [startDeposit] = useMutation(SaleWatchDepositWalletDocument);
  const [runPurchase] = useMutation(SaleRunDepositWalletPurchaseDocument);

  const depositAddress = depositData?.tokenSale.getDepositWallet.walletAddress;
  const depositWalletWatched =
    depositData?.tokenSale.getDepositWallet.isWatched;

  const refetchPurchaseTableData = () => {
    investorClient.refetchQueries({
      include: [SaleGetPurchaseHistoryDocument],
    });
  };

  const {
    startPolling: startRefreshPurchaseTableData,
    stopPolling: stopRefreshingPurchaseTableData,
  } = usePollInterval({
    callback: refetchPurchaseTableData,
  });

  const {
    data: depositWalletBalance,
    isLoading: depositWalletBalanceLoading,
    refetch: refetchDepositWalletBalance,
  } = useBalance({
    address: depositAddress as `0x${string}`,
    token: contracts.tether.address,
    enabled: Boolean(address),
  });

  const {
    data: depositMaticBalance,
    isLoading: depositMaticBalanceLoading,
    refetch: refetchDepositMaticBalance,
  } = useBalance({
    address: depositAddress as `0x${string}`,
    enabled: Boolean(address),
  });

  const discounts = getUserDiscounts(user?.discounts);

  const wofrPriceWeiDiscounted = wofrPriceData
    ? (BigInt(wofrPriceData) * BigInt((100 - discounts.total) * 10)) /
      BigInt(1000)
    : BigInt(0);

  const wofrPriceWithoutDiscount = formatEther(wofrPriceData || BigInt(0));
  const wofrPriceDiscounted = formatEther(wofrPriceWeiDiscounted);

  const getTokensUsdtPriceInWei = (
    wofrPrice: bigint | undefined,
    tokensAmount: string,
  ) => {
    if (!wofrPrice) {
      return BigInt('0');
    }

    return (wofrPrice * parseEther(tokensAmount)) / parseEther('1');
  };

  const handleChangeWOFR = (val: string) => {
    setAmountWOFR(val);

    const usdtAmountWei = getTokensUsdtPriceInWei(wofrPriceWeiDiscounted, val);
    const usdtAmountEther = formatEther(usdtAmountWei);

    setAmountUSDT(
      toFixed(usdtAmountEther, INPUT_PRECISION, ToFixedRounding.Up),
    );
  };

  const handleChangeUSDT = (val: string) => {
    setAmountUSDT(val);

    const wofrAmount = parseFloat(val) / parseFloat(wofrPriceDiscounted);

    setAmountWOFR(
      isNaN(wofrAmount) ? '0' : toFixed(wofrAmount, INPUT_PRECISION),
    );
  };

  const startSession = useCallback(async () => {
    setCurrentStep({ stepIndex: 0, lineIndex: 1 });
    stopRefreshingPurchaseTableData();

    try {
      await startDeposit();
      await refetchWalletWatched();
      await runPurchase();
    } catch (e) {
      console.error(e);
      setCurrentStep({ stepIndex: 0, lineIndex: 0, isError: true });
      toast(
        <ToastNotification
          title="Error:"
          message="Can't start session"
          type={NotificationType.error}
        />,
        { autoClose: false },
      );
    }
  }, [
    refetchWalletWatched,
    runPurchase,
    startDeposit,
    stopRefreshingPurchaseTableData,
  ]);

  const handleDepositReceived = useCallback(async () => {
    if (!depositWalletBalance || +depositWalletBalance.formatted === 0) {
      return;
    }

    if (
      depositWalletBalance &&
      +depositWalletBalance?.formatted > MIN_DEPOSIT_AMOUNT
    ) {
      toast(
        <ToastNotification
          icon="usdt"
          title="Success"
          message={`Your deposit is received. Processing purchase.`}
        />,
      );

      await runPurchase();
    } else {
      setCurrentStep({ stepIndex: 0, lineIndex: 0 });
      toast(
        <ToastNotification
          icon="usdt"
          type={NotificationType.error}
          title="Deposit is not enough to start purchase"
          message={`Your deposit is ${depositWalletBalance?.formatted} USDT is not enough to start purchase. Minimum required deposit is ${MIN_DEPOSIT_AMOUNT} USDT`}
        />,
        { autoClose: false },
      );
    }
  }, [depositWalletBalance, runPurchase]);

  useEffect(() => {
    handleDepositReceived();
  }, [depositWalletBalance, handleDepositReceived]);

  useEffect(() => {
    if (!depositAddress) {
      return;
    }

    const client = createPublicClient({
      chain: defaultChain,
      transport: webSocket(appConfig.websocketRpcUrl),
    });
    const unwatchReceivedDeposit = client?.watchContractEvent({
      ...contracts.tether,
      eventName: 'Transfer',
      args: {
        to: depositAddress as `0x${string}`,
      },
      onLogs: async (logs) => {
        await refetchDepositWalletBalance();
      },
    });

    const unwatchDepositTaken = client?.watchContractEvent({
      ...contracts.tether,
      eventName: 'Transfer',
      args: {
        from: depositAddress as `0x${string}`,
      },
      onLogs: async (logs) => {
        setIsDepositTaken(true);

        try {
          const usdtSpentAmount = Number(
            formatUnits(logs?.[0]?.args?.value as bigint, 6),
          );

          const wofrBought = usdtSpentAmount / +wofrPriceDiscounted;

          const gAEventName =
            balanceWOFR != undefined && +balanceWOFR === 0
              ? 'first_token_purchase'
              : 'repeat_token_purchase';

          const referrals_total_usdt = user?.invitedByCode
            ? ((user?.referralCodeDiscountPercent || 0) * usdtSpentAmount) / 100
            : 0;

          sendAnalyticsEvent({
            name: gAEventName,
            user_id: user?.address,
            variables: {
              purchase_method: 'easy_way',
              referrals_code: user?.invitedByCode || '',
              referrals_total_usdt: referrals_total_usdt.toFixed(2),
              referral_discount: discounts.referral,
              nft_discount: discounts.nft,
              whitelist_discount: discounts.whitelist,
              purchase_total_wofr: wofrBought.toFixed(2),
              purchase_total_usdt: usdtSpentAmount.toFixed(2),
            },
          });
        } catch (e) {
          console.error(e);
        }

        await refetchWalletWatched();
        await refetchDepositWalletBalance();
        await refetchDepositMaticBalance();

        toast(
          <ToastNotification
            icon="wofr"
            title="Success"
            message={`Your purchase of WOFR tokens is completed!`}
          />,
        );

        setTimeout(() => {
          refetchWalletWatched();
        }, 1000);

        setTimeout(() => {
          refetchWalletWatched();
        }, 3000);
      },
    });

    return () => {
      unwatchReceivedDeposit?.();
      unwatchDepositTaken?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositAddress, depositWalletWatched, depositWalletBalance]);

  useEffect(() => {
    if (
      !depositWalletWatched &&
      depositWalletBalance &&
      +depositWalletBalance?.formatted === 0
    ) {
      setCurrentStep({ stepIndex: 0, lineIndex: 0 });
    }

    if (
      !depositWalletWatched &&
      depositWalletBalance &&
      +depositWalletBalance?.formatted > MIN_DEPOSIT_AMOUNT
    ) {
      startSession();
    }

    if (
      depositWalletBalance &&
      +depositWalletBalance?.formatted > MIN_DEPOSIT_AMOUNT &&
      depositWalletWatched
    ) {
      setCurrentStep({ stepIndex: 1, lineIndex: 1 });
    }

    if (isDepositTaken && !depositWalletWatched) {
      setCurrentStep({ stepIndex: 2, lineIndex: 2 });
      setIsDepositTaken(false);
      startRefreshPurchaseTableData();
    }
  }, [
    depositWalletBalance,
    depositWalletBalance?.formatted,
    depositWalletWatched,
    isDepositTaken,
    startRefreshPurchaseTableData,
    startSession,
  ]);

  return {
    amountWOFR,
    amountUSDT,
    depositAddress,
    wofrPriceWithoutDiscount,
    wofrPriceDiscounted,
    discounts,
    buySteps: BUY_STEPS,
    currentStep,
    depositWalletBalanceLoading,
    depositWalletBalance,
    depositMaticBalance: depositMaticBalance?.formatted,
    depositMaticBalanceLoading,
    minDepositAmount: MIN_DEPOSIT_AMOUNT,
    handleChangeWOFR,
    handleChangeUSDT,
    startSession,
  };
};
