import React from 'react';
import cn from 'classnames';

import { Icon, IconName } from '@app/ui-kit';

import s from './ProgressIndicator.module.scss';

export type ProgressStep = {
  stepIndex: number;
  lineIndex: number;
  isError?: boolean;
};

export interface ProgressIndicatorProps {
  steps: string[];
  currentStep: ProgressStep;
  className?: string;
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  steps,
  currentStep,
  className,
}) => {
  const getIconName = (index: number): IconName => {
    if (index === steps.length - 1 && index < currentStep.stepIndex) {
      return 'success';
    }

    if (index === currentStep.stepIndex && currentStep.isError) {
      return 'alert';
    }

    if (index < currentStep.stepIndex) {
      return 'progressCircleSolid';
    }

    return 'progressCircleDashed';
  };

  return (
    <div className={cn(s.root, className)}>
      {steps.map((step, index) => {
        const iconName = getIconName(index);

        return (
          <React.Fragment key={step}>
            <div
              className={cn(s.step, {
                [s.active]: index === currentStep.stepIndex,
                [s.completed]: index < currentStep.stepIndex,
                [s.error]:
                  index === currentStep.stepIndex && currentStep.isError,
              })}
            >
              <Icon name={iconName} className={s.progressCircle} />
              <div
                className={cn(s.stepText, {
                  [s.firstStepText]: index === 0,
                  [s.lastStepText]: index === steps.length - 1,
                })}
              >
                {step}
              </div>
            </div>

            {index < steps.length - 1 && (
              <div
                className={cn(s.divider, {
                  [s.completed]: index < currentStep.lineIndex,
                })}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ProgressIndicator;
