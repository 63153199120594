import React from 'react';
import cn from 'classnames';

import { LoadingIndicator } from '@app/ui-kit';

import s from './DepositPurchaseFeeIndicator.module.scss';

interface DepositPurchaseFeeIndicatorProps {
  maticBalance: string | undefined;
  isLoading: boolean;
  className?: string;
}

const REQUIRED_MATIC_FOR_FREE_TX = 0.1;
const TX_FEE = 0.2;
const ZERO_FEE = 0;

const DepositPurchaseFeeIndicator = ({
  maticBalance,
  isLoading,
  className,
}: DepositPurchaseFeeIndicatorProps) => {
  const fee =
    maticBalance && +maticBalance >= REQUIRED_MATIC_FOR_FREE_TX
      ? ZERO_FEE
      : TX_FEE;

  if (fee === ZERO_FEE) {
    return null;
  }

  return (
    <div className={cn(s.root, className)}>
      <span>Estimated Final Transaction Fee</span>
      <div className={s.wrapper}>
        <LoadingIndicator
          valueToObserve={isLoading}
          width="30px"
          height={'17px'}
        >
          <span className={s.value}>{fee}</span>
        </LoadingIndicator>
        <span>USDT</span>
      </div>
    </div>
  );
};

export default DepositPurchaseFeeIndicator;
