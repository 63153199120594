import cn from 'classnames';
import { RefObject } from 'react';

import { useVerticalScrollGradient } from '@app/hooks';

import s from './TableScrollGradient.module.scss';

interface TableScrollGradientProps {
  itemsCount: number;
  scrollBodyRef: RefObject<HTMLDivElement>;
}

const TableScrollGradient: React.FC<TableScrollGradientProps> = ({
  itemsCount,
  scrollBodyRef,
}) => {
  const desktopOverflow = itemsCount > 4;
  const mobileOverflow = itemsCount > 5;
  const gradientVisible = useVerticalScrollGradient(
    scrollBodyRef,
    Boolean(desktopOverflow || mobileOverflow),
  );

  return (
    <>
      <div
        className={cn(s.gradient, s.top, {
          [s.enabledMobile]: mobileOverflow,
          [s.visible]: gradientVisible.top,
        })}
      />
      <div
        className={cn(s.gradient, s.bottom, {
          [s.enabledMobile]: mobileOverflow,
          [s.visible]: gradientVisible.bottom,
        })}
      />
      <div
        className={cn(s.gradient, s.top, {
          [s.enabledDesktop]: desktopOverflow,
          [s.visible]: gradientVisible.top,
        })}
      />
      <div
        className={cn(s.gradient, s.bottom, {
          [s.enabledDesktop]: desktopOverflow,
          [s.visible]: gradientVisible.bottom,
        })}
      />
    </>
  );
};

export default TableScrollGradient;
