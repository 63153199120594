import cn from 'classnames';
import { AnimatePresence, m } from 'framer-motion';

import { Icon } from '@app/ui-kit';
import { formatHash, numberWithCommas } from '@app/utils';
import { FRAMER_OPACITY_TRANSITION } from '@app/constants/framerTransitions';
import { TableItemType, TableRowItem } from '@app/components/Table';

import s from './TableItem.module.scss';

interface TableProps extends TableRowItem {
  maxWidth?: string;
  mobileHidden?: boolean;
  className?: string;
}

const TableItem: React.FC<TableProps> = ({
  type,
  value,
  valueLabel,
  valueWithCommas,
  maxWidth,
  mobileHidden,
  className,
}) => {
  let renderedItem = null;

  switch (type) {
    case TableItemType.amount:
      renderedItem = (
        <>
          {valueWithCommas ? numberWithCommas(value) : value}{' '}
          {valueLabel ? valueLabel : null}
        </>
      );

      break;
    case TableItemType.date:
      renderedItem = new Date(value).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      break;

    case TableItemType.address:
      renderedItem = formatHash(value as string);
      break;

    case TableItemType.explorerLink:
      renderedItem = (
        <a
          href={value as string}
          target="_blank"
          rel="noreferrer"
          className={s.explorerLink}
        >
          <Icon name="externalLink" />
        </a>
      );
      break;
    default:
      renderedItem = null;
  }

  return (
    <AnimatePresence>
      <m.div
        className={cn(s.root, className, { [s.mobileHidden]: mobileHidden })}
        style={maxWidth ? { maxWidth: maxWidth } : undefined}
        {...FRAMER_OPACITY_TRANSITION}
      >
        {renderedItem}
      </m.div>
    </AnimatePresence>
  );
};

export default TableItem;
