import { Input } from '@app/ui-kit';
import DiscountIndicator from '@app/components/DiscountIndicator';
import { useDepositBuyWOFR } from '@app/hooks/useDepositBuyWOFR';

import s from './CardDepositCalculation.module.scss';

const CardDepositCalculation = () => {
  const {
    amountUSDT,
    amountWOFR,
    wofrPriceWithoutDiscount,
    wofrPriceDiscounted,
    discounts,
    handleChangeUSDT,
    handleChangeWOFR,
  } = useDepositBuyWOFR();

  return (
    <div className={s.root}>
      <DiscountIndicator
        nftDiscount={discounts.nft}
        referralDiscount={discounts.referral}
        whitelistDiscount={discounts.whitelist}
        className={s.discount}
      />
      <Input
        value={amountWOFR}
        onChange={handleChangeWOFR}
        topLabel="Amount"
        topChip="WOFR"
        bottomLabel="1 WOFR ="
        bottomValue={`${wofrPriceWithoutDiscount} USDT`}
        bottomNewValue={
          wofrPriceDiscounted !== wofrPriceWithoutDiscount
            ? `${wofrPriceDiscounted} USDT`
            : undefined
        }
        icon="wofr"
        numbersOnly
        placeholder="0"
        aria-label="WOFR token input"
        inputMode="numeric"
      />

      <Input
        value={amountUSDT}
        onChange={handleChangeUSDT}
        topLabel="Amount"
        topChip="USDT"
        icon="usdt"
        placeholder="0"
        numbersOnly
        aria-label="USDT input"
        inputMode="numeric"
      />
    </div>
  );
};

export default CardDepositCalculation;
