'use client';

import { withAuth } from '@app/hocs/withAuth';
import CardBuyWOFR from '@app/components/CardBuyWOFR';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import { CardPurchaseHistory } from '@app/components/CardPurchaseHistory';
import CardRound from '@app/components/CardRound';

import s from './PurchasePage.module.scss';
import { Tabs } from '@app/ui-kit/Tabs';
import ItemRound from '@app/components/ItemRound';
import { useState } from 'react';

const rounds = [
  {
    media: '/media/round/PrivateRound.png',
    title: 'Private Round',
    desc: 'Contact us via contact@wormfare.com',
    price: 0.03,
    disable: false,
  },
  {
    media: '/media/round/Crowdfunding.png',
    title: 'Crowdfunding R1',
    desc: 'Sold Out!',
    subtitle: '10M WOFR allocated',
    price: 0.03125,
    disable: true,
  },
  {
    media: '/media/round/Crowdfunding-R2.png',
    title: 'Crowdfunding R2',
    desc: 'Buy Now!',
    subtitle: '6M WOFR allocated',
    price: 0.045,
    disable: false,
  },
  {
    media: '/media/round/KOLRound.png',
    title: 'KOL Round',
    desc: 'Contact us via contact@wormfare.com',
    price: 0.035,
    disable: false,
  },
];

const PurchasePage = () => {
  const [activeSlide, setActiveSlide] = useState<number>(2);

  const handleChangeTab = (swiper: SwiperClass) => {
    const slide = rounds[swiper.activeIndex];

    console.log(slide);

    if (!slide.disable) {
      setActiveSlide(swiper.activeIndex);
    }
  };

  const handleClick = (index: number, disable: boolean) => {
    if (!disable) {
      setActiveSlide(index);
    }
  };

  return (
    <div className={s.root}>
      <Swiper
        className={s.slider}
        cssMode
        centeredSlides
        spaceBetween={8}
        slidesPerView="auto"
        initialSlide={activeSlide}
        slideToClickedSlide
        breakpoints={{
          990: {
            enabled: false,
            centeredSlides: false,
          },
        }}
        onSlideChange={handleChangeTab}
      >
        {rounds.map((round, index) => (
          <SwiperSlide
            className={s.slide}
            key={index}
            onClick={() => {
              handleClick(index, round.disable);
            }}
          >
            <ItemRound {...round} isActive={activeSlide === index} />
          </SwiperSlide>
        ))}
      </Swiper>
      {activeSlide === 2 && (
        <div className={s.tabContent}>
          <h1 className={s.tabHeading}>Crowdfunding</h1>
          <CardBuyWOFR />
          <CardPurchaseHistory className={s.history} />
        </div>
      )}
      {activeSlide === 0 && (
        <div className={s.tabContent}>
          <h1 className={s.tabHeading}>Private Round</h1>
          <CardRound
            btnLabel="Contact Us"
            btnLink="https://docs.google.com/forms/d/e/1FAIpQLSdJhyWFaHcV90gqxg7rXl7FpL0_JfCAnX-rL7_dK4F20M3bMA/viewform"
          >
            <p className={s.text}>
              Private Round is designed for savvy investors looking to
              capitalize on the cutting-edge intersection of gaming and
              blockchain technology.
            </p>
            <p className={s.text}>
              With personalized conditions, we cater to those who see the
              potential for significant returns and wish to play a crucial role
              in the growth of our ecosystem.
            </p>
            <p className={s.text}>
              Contact us via contact@wormrare.com to discuss investment
              opportunities tailored to your objectives.
            </p>
          </CardRound>
        </div>
      )}
      {activeSlide === 3 && (
        <div className={s.tabContent}>
          <h1 className={s.tabHeading}>KOL Round</h1>
          <CardRound
            btnLabel="Contact Us"
            btnLink="https://docs.google.com/forms/d/e/1FAIpQLSdJhyWFaHcV90gqxg7rXl7FpL0_JfCAnX-rL7_dK4F20M3bMA/viewform"
          >
            <p className={s.text}>
              KOL Round is specially crafted for key opinion leaders passionate
              about sustainability and innovation in the gaming and blockchain
              spaces.
            </p>
            <p className={s.text}>
              If you have a strong following and wish to leverage your platform
              for a greater cause, we invite you to join us. Let&apos;s
              collaborate to amplify our impact.
            </p>
            <p className={s.text}>
              Contact us via contact@wormrare.com to explore exclusive
              conditions tailored to your influential role.
            </p>
          </CardRound>
        </div>
      )}
    </div>
  );
};

const PurchasePageWithAuth = withAuth(PurchasePage);

export default PurchasePageWithAuth;
