import { ButtonSecondary, TabsSwitcher, TabsSwitcherStyle } from '@app/ui-kit';
import { useState } from 'react';

import BuyWOFRClassic from '@app/components/BuyWOFRClassic';
import ModalHowToVideoGuide from '@app/components/ModalHowToVideoGuide';
import BuyWOFRDeposit from '@app/components/BuyWOFRDeposit';
import { useAuthContext } from '@app/context/AuthContextProvider';

import s from './CardBuyWOFR.module.scss';

const TAB_OPTIONS = [
  { key: 'deposit', value: 'Easy Way' },
  { key: 'classic', value: 'For Geeks' },
];

const VIDEO_BUY_CLASSIC =
  'https://www.youtube.com/embed/QyqACdhSX1A?si=59nhSHnvonhxcw5D';

const VIDEO_BUY_DEPOSIT = 'todo';

const CardBuyWOFR = () => {
  const [activeTab, setActiveTab] = useState(TAB_OPTIONS[0].key);
  const { isVerified, openVerifyModal } = useAuthContext();

  const handleChangeTab = (tab: string) => {
    if (isVerified) {
      setActiveTab(tab);
    }

    openVerifyModal();
  };

  return (
    <div className={s.root}>
      <div className={s.card}>
        <div className={s.row}>
          <h3 className={s.title}>BUY WOFR</h3>
          {activeTab === 'classic' && (
            <ModalHowToVideoGuide
              youtubeUrl={VIDEO_BUY_CLASSIC}
              buttonLabel={'How to Buy?'}
              buttonIcon={'video'}
            />
          )}
          {activeTab === 'deposit' && (
            <ButtonSecondary
              label={'Need Help?'}
              iconName="helpCircleOutline"
              onClick={() => window.Tawk_API.toggle()}
            />
          )}

          {/* <ModalHowToVideoGuide
            youtubeUrl={
              activeTab === TAB_OPTIONS[0].key
                ? VIDEO_BUY_CLASSIC
                : VIDEO_BUY_DEPOSIT
            }
            buttonLabel={'How to Buy?'}
            buttonIcon={'video'}
          /> */}
        </div>
        <TabsSwitcher
          tabOptions={TAB_OPTIONS}
          activeTab={activeTab}
          onClick={handleChangeTab}
          style={TabsSwitcherStyle.small}
          className={s.tabsSwitcher}
        />
        {activeTab === 'classic' && <BuyWOFRClassic />}
        {activeTab === 'deposit' && <BuyWOFRDeposit />}
      </div>
    </div>
  );
};

export default CardBuyWOFR;
