import cn from 'classnames';
import { ButtonMain, ButtonMainSize, ButtonMainStyle } from '@app/ui-kit';

import s from './CardRound.module.scss';
import { ReactNode } from 'react';

interface CardRoundProps {
  className?: string;
  children: ReactNode;
  btnLink: string;
  btnLabel: string;
}

const CardRound: React.FC<CardRoundProps> = ({
  className,
  children,
  btnLink,
  btnLabel,
}) => {
  return (
    <div className={cn(s.root, className)}>
      {children}
      <ButtonMain
        label={btnLabel}
        type="button"
        size={ButtonMainSize.normal}
        style={ButtonMainStyle.white}
        className={s.buyButton}
        as="a"
        href={btnLink}
      />
    </div>
  );
};

export default CardRound;
