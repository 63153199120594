import cn from 'classnames';
import { formatEther } from 'viem';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { Table, TableHeadItem, TableItemType } from '@app/components/Table';
import {
  SaleGetPurchaseHistoryDocument,
  SaleGetPurchaseHistoryQuery,
} from '@app/__generated__/graphql';
import { etherScanTxLink } from '@app/utils';

import s from './CardPurchaseHistory.module.scss';

interface CardPurchaseHistoryProps {
  className?: string;
}

const TABLE_HEAD: TableHeadItem[] = [
  { label: 'Amount' },
  { label: 'Amount Paid', mobileHidden: true },
  { label: 'Purchased at' },
  { label: 'Explorer', mobileHidden: true, maxWidth: '60px' },
];

const CardPurchaseHistory: React.FC<CardPurchaseHistoryProps> = ({
  className,
}) => {
  const { data, loading } = useQuery<SaleGetPurchaseHistoryQuery>(
    SaleGetPurchaseHistoryDocument,
  );

  const claimsMapped = useMemo(() => {
    const items = data?.tokenSale?.getPurchaseHistory || [];

    return items.map((item) => {
      const rowItems = [
        {
          type: TableItemType.amount,
          value: formatEther(BigInt(item.amount)),
          valueWithCommas: true,
          valueLabel: 'WOFR',
        },
        {
          type: TableItemType.amount,
          value: formatEther(BigInt(item.amountUsdt)),
          valueWithCommas: true,
          valueLabel: 'USDT',
        },
        {
          type: TableItemType.date,
          value: item.createdAt,
        },
        {
          type: TableItemType.explorerLink,
          value: etherScanTxLink(item.transactionHash),
        },
      ];

      return {
        items: rowItems,
      };
    });
  }, [data]);

  return (
    <div className={cn(s.root, className)}>
      <div className={s.card}>
        <span className={s.title}>Purchase History</span>
        <Table head={TABLE_HEAD} items={claimsMapped} isLoading={loading} />
      </div>
    </div>
  );
};

export default CardPurchaseHistory;
