import { AnimatePresence, m } from 'framer-motion';
import { useState } from 'react';
import cn from 'classnames';

import {
  ButtonSecondary,
  Icon,
  Input,
  InputActionButtonType,
  LoadingIndicator,
} from '@app/ui-kit';
import ProgressIndicator from '@app/components/ProgressIndicator';
import { useDepositBuyWOFR } from '@app/hooks/useDepositBuyWOFR';
import CardDepositCalculation from '@app/components/CardDepositCalculation';
import DepositPurchaseFeeIndicator from '@app/components/DepositPurchaseFeeIndicator';

import s from './BuyWOFRDeposit.module.scss';
import { toast } from 'react-toastify';
import ToastNotification from '../ToastNotification';

export const CALC_ANIMATION = {
  initial: { opacity: 0, maxHeight: 0 },
  animate: { opacity: 1, maxHeight: 320 },
  exit: { opacity: 0, maxHeight: 0 },
  transition: {
    type: 'tween',
    duration: 0.25,
  },
};

const BuyWOFRDeposit = () => {
  const [calcOpen, setCalcOpen] = useState(false);
  const {
    depositAddress,
    currentStep,
    buySteps,
    depositWalletBalance,
    depositWalletBalanceLoading,
    depositMaticBalance,
    depositMaticBalanceLoading,
    minDepositAmount,
  } = useDepositBuyWOFR();

  const toggleDepositCalc = () => {
    setCalcOpen(!calcOpen);
  };

  const handleCopyClick = () => {
    toast(
      <ToastNotification
        title="Copied deposit wallet address"
        message={`Please deposit USDT in Polygon network only to copied address: ${depositAddress}`}
      />,
    );
  };

  return (
    <div className={s.root}>
      <div className={s.warning}>
        <Icon name="alertWarning" className={s.alertIcon} />
        <span>
          You should deposit USDT in <b>Polygon network</b> only. If you deposit
          in wrong network your funds will be lost. <br />
          <b>Don&apos;t deposit MATIC to this address.</b>
        </span>
      </div>
      <Input
        value={depositAddress || 'Loading wallet address...'}
        topLabel="Your Deposit Address"
        icon="code"
        actionButton={{
          label: 'Copy',
          type: InputActionButtonType.copy,
        }}
        onActionButtonClick={handleCopyClick}
        onChange={() => {}}
      />
      <div className={s.depositBalance}>
        Deposit Balance:{' '}
        <LoadingIndicator
          width="60px"
          height="24px"
          valueToObserve={
            depositWalletBalanceLoading || depositWalletBalance === undefined
          }
        >
          <span
            className={cn(s.balance, {
              [s.green]:
                depositWalletBalance &&
                +depositWalletBalance?.formatted > minDepositAmount,
            })}
          >
            {depositWalletBalance?.formatted} USDT
          </span>
        </LoadingIndicator>
      </div>
      <DepositPurchaseFeeIndicator
        maticBalance={depositMaticBalance}
        isLoading={depositMaticBalanceLoading}
      />
      <div className={s.progressWrapper}>
        <div className={s.progressTitle}>You can track your purchase here</div>
        <div className={s.progressNotice}>
          Note: When your deposit is received, it can take up to 10 minutes to
          complete purchase.
        </div>
        <ProgressIndicator
          steps={buySteps}
          currentStep={currentStep}
          className={s.progress}
        />
      </div>
      <ButtonSecondary
        label={
          calcOpen ? 'Close Deposit calculator' : 'Open Deposit Calculator'
        }
        iconName="calculator"
        onClick={toggleDepositCalc}
      />
      <AnimatePresence>
        {calcOpen && (
          <m.div {...CALC_ANIMATION}>
            <CardDepositCalculation />
          </m.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BuyWOFRDeposit;
