/* eslint-disable @next/next/no-img-element */

import { useEffect, useState } from 'react';
import cn from 'classnames';

import { LoadingIndicator } from '@app/ui-kit';
import { numberWithCommas } from '@app/utils';

import s from './ItemRound.module.scss';

interface ItemRoundProps {
  className?: string;
  media: string;
  title: string;
  subtitle?: string;
  desc: string;
  price: number;
  isActive: boolean;
  disable?: boolean;
  onClick?: (id: number) => void;
}

const ItemRound: React.FC<ItemRoundProps> = ({
  className,
  media,
  price,
  title,
  subtitle,
  desc,
  isActive,
  disable,
  onClick,
}) => {
  const [imgLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();

    img.src = media;

    const handleImageLoad = () => {
      setImageLoaded(true);
    };

    img.addEventListener('load', handleImageLoad);

    return () => {
      img.removeEventListener('load', handleImageLoad);
    };
  }, [media]);

  return (
    <div
      className={cn(
        s.root,
        className,
        { [s.isActive]: isActive },
        { [s.disable]: disable },
      )}
    >
      <div className={s.contentWrapper}>
        <div className={s.imageWrapper}>
          <LoadingIndicator valueToObserve={!imgLoaded} height={'100px'}>
            <img src={media} alt={title} className={s.image} />
          </LoadingIndicator>
        </div>
        <div className={s.textWrapper}>
          <span className={s.title}>{title}</span>
          <span className={s.price}>${`${numberWithCommas(price)}`}</span>
          <span className={s.subtitle}>{subtitle}</span>
        </div>
      </div>

      <div className={s.descWrapper}>
        <span className={s.desc}>{desc}</span>
      </div>
    </div>
  );
};

export default ItemRound;
