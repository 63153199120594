import {
  ButtonMain,
  ButtonMainSize,
  Input,
  InputActionButtonType,
} from '@app/ui-kit';
import { numberWithCommas } from '@app/utils';
import { useClassicBuyWOFR } from '@app/hooks';
import DiscountIndicator from '@app/components/DiscountIndicator';
import ProgressIndicator from '@app/components/ProgressIndicator';

import s from './BuyWOFRClassic.module.scss';

const BuyWOFRClassic = () => {
  const {
    amountUSDT,
    amountWOFR,
    maxWOFR,
    wofrPriceWithoutDiscount,
    wofrPriceDiscounted,
    balance,
    termsAgreed,
    buyDisabled,
    isLoading,
    discounts,
    classicBuySteps,
    currentStep,
    handleChangeUSDT,
    handleChangeWOFR,
    setTermsAgreed,
    buyWOFR,
  } = useClassicBuyWOFR();

  return (
    <form className={s.root} onSubmit={buyWOFR}>
      <Input
        value={amountWOFR}
        onChange={handleChangeWOFR}
        topLabel="Amount"
        topChip="WOFR"
        bottomLabel="1 WOFR ="
        bottomValue={`${wofrPriceWithoutDiscount} USDT`}
        bottomNewValue={
          wofrPriceDiscounted !== wofrPriceWithoutDiscount
            ? `${wofrPriceDiscounted} USDT`
            : undefined
        }
        icon="wofr"
        numbersOnly
        placeholder="0"
        maxValue={maxWOFR}
        actionButton={{
          label: 'MAX',
          type: InputActionButtonType.setMax,
        }}
        disabled={isLoading}
        aria-label="WOFR token input"
        inputMode="numeric"
      />
      <DiscountIndicator
        nftDiscount={discounts.nft}
        referralDiscount={discounts.referral}
        whitelistDiscount={discounts.whitelist}
        className={s.discount}
      />
      <Input
        value={amountUSDT}
        onChange={handleChangeUSDT}
        topLabel="Amount"
        topChip="USDT"
        bottomLabel="Your wallet balance:"
        bottomValue={`${numberWithCommas(balance)} USDT`}
        icon="usdt"
        maxValue={balance}
        actionButton={{
          label: 'MAX',
          type: InputActionButtonType.setMax,
        }}
        placeholder="0"
        numbersOnly
        disabled={isLoading}
        aria-label="USDT input"
        inputMode="numeric"
      />
      {/* <div className={s.agreementRow}>
        <Checkbox isChecked={termsAgreed} onClick={setTermsAgreed} />
        <div>
          I have read{' '}
          <a href="/terms-of-service" target="_blank" className={s.link}>
            Terms of Service
          </a>{' '}
          and fully agree
        </div>
      </div> */}
      <div className={s.progressWrapper}>
        <div className={s.progressTitle}>You can track your purchase here</div>
        <ProgressIndicator
          steps={classicBuySteps}
          currentStep={currentStep}
          className={s.progress}
        />
      </div>
      <ButtonMain
        label="BUY WOFR"
        type="submit"
        disabled={buyDisabled}
        isLoading={isLoading}
        size={ButtonMainSize.big}
        className={s.buyButton}
      />
    </form>
  );
};

export default BuyWOFRClassic;
