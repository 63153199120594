export type TableHeadItem = {
  label: string;
  mobileHidden?: boolean;
  maxWidth?: string;
};

export enum TableItemType {
  date,
  address,
  amount,
  explorerLink,
}

export type TableRowItem = {
  type: TableItemType;
  value: string | number;
  valueLabel?: string;
  valueWithCommas?: boolean;
};

export type TableRow = {
  items: TableRowItem[];
};
