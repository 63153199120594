import cn from 'classnames';
import { AnimatePresence, m } from 'framer-motion';
import { useRef } from 'react';

import { LoadingIndicator } from '@app/ui-kit';
import { TableHeadItem, TableRow } from '@app/components/Table';
import TableItem from './TableItem';
import TableScrollGradient from './TableScrollGradient';

import s from './Table.module.scss';

interface TableProps {
  head: TableHeadItem[];
  items: TableRow[];
  isLoading: boolean;
  infiniteScrollAnchor?: JSX.Element | null;
  className?: string;
}

const Table: React.FC<TableProps> = ({
  head,
  items,
  isLoading,
  infiniteScrollAnchor,
  className,
}) => {
  const bodyRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={cn(s.root, className)}>
      <div className={s.head}>
        {head.map(({ label, maxWidth, mobileHidden }) => (
          <div
            key={label}
            className={cn(s.headLabel, {
              [s.mobileHidden]: mobileHidden,
              [s.explorer]: label.includes('Explorer'),
            })}
            style={maxWidth ? { maxWidth } : undefined}
          >
            {label}
          </div>
        ))}
      </div>
      <div
        className={cn(s.body, {
          [s.negativeMarginDesktop]: items && items?.length > 3,
        })}
        ref={bodyRef}
      >
        <AnimatePresence>
          {new Array(3).fill(null).map((el, i) => (
            <LoadingIndicator
              valueToObserve={isLoading}
              height="44px"
              width="100%"
              key={i}
            />
          ))}
          {!isLoading &&
            items?.map((row, rowIndex) => {
              return (
                <div key={JSON.stringify(row) + rowIndex} className={s.row}>
                  {row.items.map((el, itemIndex) => {
                    return (
                      <TableItem
                        key={`${el.type}-${el.value}-${itemIndex}`}
                        maxWidth={head[itemIndex]?.maxWidth}
                        mobileHidden={head[itemIndex]?.mobileHidden}
                        {...el}
                      />
                    );
                  })}
                </div>
              );
            })}
          {infiniteScrollAnchor}
          {/* TODO show something if table has no data */}
          {!isLoading && (!items || !items.length) && (
            <div className={s.row}>No items found</div>
          )}
        </AnimatePresence>
      </div>
      <TableScrollGradient
        itemsCount={items.length || 0}
        scrollBodyRef={bodyRef}
      />
    </div>
  );
};

export default Table;
