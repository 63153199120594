'use client';

import ConnectYourWallet from '@app/components/ConnectYourWallet';
import { useAuthContext } from '@app/context/AuthContextProvider';
import { Loader } from '@app/ui-kit';

const withAuth = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const WithAuth: React.FC<P> = (props) => {
    const { isAuth, isLoading } = useAuthContext();

    if (isLoading === false && !isAuth) {
      return <ConnectYourWallet />;
    }

    if (isLoading === false && isAuth) {
      return <WrappedComponent {...props} />;
    }

    return <Loader />;
  };

  return WithAuth;
};

export default withAuth;
