import Image from 'next/image';

import s from './ConnectYourWallet.module.scss';

const ConnectYourWallet = () => {
  return (
    <div className={s.root}>
      <Image
        src="/media/connect-your-wallet.png"
        width={614}
        height={403}
        alt="connect your wallet"
        className={s.image}
      />
    </div>
  );
};

export default ConnectYourWallet;
