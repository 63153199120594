import React from 'react';
import cn from 'classnames';

import { ButtonSecondary, Icon, Tooltip } from '@app/ui-kit';
import { MAX_NFT_DISCOUNT } from '@app/constants/app';

import s from './DiscountIndicator.module.scss';
import { GenesisNftsListDocument } from '@app/__generated__/graphql';
import { useQuery } from '@apollo/client';
import Link from 'next/link';

interface DiscountIndicatorProps {
  nftDiscount: number;
  referralDiscount: number;
  whitelistDiscount: number;
  className?: string;
}

const DiscountIndicator = ({
  nftDiscount,
  referralDiscount,
  whitelistDiscount,
  className,
}: DiscountIndicatorProps) => {
  const totalDiscount = nftDiscount + referralDiscount + whitelistDiscount;
  const { data, loading, refetch } = useQuery(GenesisNftsListDocument);
  const userHasNfts = data?.genesisNft.listNfts.length;

  return (
    <div className={cn(s.root, className)}>
      <Tooltip
        tooltip={
          <div className={s.tooltip}>
            <div className={s.cell}>
              <span>Referral</span>
              <span className={s.tooltipBoldText}>{referralDiscount}%</span>
            </div>
            <div className={s.cell}>
              <span>NFT</span>
              <span className={s.tooltipBoldText}>{nftDiscount}%</span>
            </div>
            <div className={s.cell}>
              <span>Whitelist</span>
              <span className={s.tooltipBoldText}>{whitelistDiscount}%</span>
            </div>
          </div>
        }
      >
        <div className={s.wrapper}>
          <span>Discount</span>
          <span className={cn(s.amount, { [s.green]: totalDiscount > 0 })}>
            {totalDiscount}%
          </span>
        </div>
      </Tooltip>
      {nftDiscount < MAX_NFT_DISCOUNT && (
        <Tooltip
          tooltip={
            <div className={s.tooltip}>
              Purchase NFTs from our OpenSea Wormfare Genesis collection. Once
              acquired, activate NFT in My Assets page, Genesis NFTs tab.
              <span className={s.tooltipBoldText}>
                Note: NFTs with higher rarity offer increased discount
                percentages
              </span>
            </div>
          }
        >
          <ButtonSecondary
            as="link"
            href="/assets/nfts"
            label={nftDiscount === 0 ? 'Add discount' : 'Increase discount'}
            iconName={'percentCircle'}
            className={cn(s.discountButton, {
              [s.highlight]: userHasNfts && nftDiscount === 0,
            })}
          />
        </Tooltip>
      )}
      {nftDiscount >= MAX_NFT_DISCOUNT && (
        <div className={s.maxDiscountLabel}>
          You’ve reached max NFT discount
        </div>
      )}
    </div>
  );
};

export default DiscountIndicator;
